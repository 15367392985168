import React, { useState, useEffect } from 'react';
import '../index.css';

import Main from '../Layouts/Main';

const App = () => {
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const isMobile = width <= 600;

  return <Main isMobile={isMobile} />;
};

export default App;
