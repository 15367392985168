import React from 'react';

const Footer = props => {
  return (
    <footer>
      <p style={{ fontWeight: 'bold' }}>
        Located in Wayne, PA and serving the tri-county Area of Chester,
        Delaware and Montgomery Counties
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ marginRight: '10px' }}>P : 610.389.1925</p>
        <div style={styles.dot} />
        <div style={{ display: 'flex' }}>
          <p style={{ marginLeft: '10px', marginRight: '4px' }}>E :</p>
          <a href="mailto: Jenifer@JDonahueLaw.com">Jenifer@JDonahueLaw.com</a>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  dot: {
    height: 6,
    width: 6,
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '2px 4px',
    alignSelf: 'center',
  },
};

export default Footer;
