import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import Biography from '../Components/Biography/Biography';
import Areas from '../Components/Areas/Areas';
import Contact from '../Components/Contact/Contact';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const Main = ({ isMobile }) => {
  return (
    <div className="ui container">
      <Header />
      <div className="content">
        <Switch>
          <Route exact path="/">
            <Home isMobile={isMobile}/>
          </Route>
          <Route path="/biography"> 
            <Biography />
          </Route>
          <Route path="/areas">
            <Areas isMobile={isMobile}/>
          </Route>
          <Route path="/contact">
            <Contact isMobile={isMobile}/>
          </Route>
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
