export default function Contact({ isMobile }) {
  const dynamicContactGrid = {
    ...styles.contactGrid,
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? '' : 'space-around',
  };

  const dynamicLeftColumn = {
    ...styles.leftColumn,
    maxWidth: isMobile ? '100%' : '40%',
    padding: isMobile ? '0px 8px' : '0px',
    textAlign: isMobile ? 'center' : '',
  };

  const dynamicHR = {
    ...styles.HRstyle,
    marginLeft: isMobile ? '' : '0px',
  };

  return (
    <>
      <div className="body-header">Contact Us</div>
      <div className="content-container contact-content-container">
        <div style={dynamicContactGrid}>
          <div style={dynamicLeftColumn}>
            <p>
              <strong>Jenifer Donahue, Esq. </strong>
              is located in Wayne, PA and serves the tri-county area of Chester,
              Delaware and Montgomery Counties.
            </p>
            <hr style={dynamicHR} />
            <div>
              <p>
                <strong>Phone:</strong> 610.389.1925
              </p>
              <p>
                <strong>Email: </strong>
                <a
                  href="mailto: Jenifer@JDonahueLaw.com"
                  style={{ color: 'black', fontFamily: 'News Cycle' }}
                >
                  Jenifer@JDonahueLaw.com
                </a>
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              alt="Contact Page"
              src="images/contact.webp"
              objectfit="cover"
              height={isMobile ? '200px' : '300px'}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  contactGrid: {
    display: 'flex',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  HRstyle: {
    marginLeft: 0,
    backgroundColor: 'black',
    height: '1px',
    width: '80%',
  },
};
