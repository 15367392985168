import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

function Navbar() {
  const history = useHistory();

  const [active, setActive] = useState(history.location.pathname);
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          setActive(window.location.pathname);
          // Handle forward event
        } else {
          setLocationKeys(keys => [location.key, ...keys]);
          setActive(window.location.pathname);
          // Handle back event
        }
      }
    });
  });

  const pageNames = [
    { name: 'Home', to: '/' },
    { name: 'Biography', to: '/biography' },
    { name: 'Areas of Practice', to: '/areas' },
    { name: 'Contact', to: '/contact' },
  ];

  const linkedButton = data => {
    return (
      <li key={data.to}>
        <NavLink
          exact
          to={data.to}
          onClick={() => setActive(data.to)}
          style={{
            background: active === data.to ? 'rgb(189, 203, 253, 0.7)' : '',
            borderRight: data.name === 'Contact' ? '' : '1px solid black',
          }}
        >
          {data.name}
        </NavLink>
      </li>
    );
  };

  return (
    <div className="navbar">
      <ul>{pageNames.map(data => linkedButton(data))}</ul>
    </div>
  );
}

export default Navbar;
