import React from 'react';
import NavBar from './Navbar'

const headerArt = () => {
  return (
    // <div className="box-container">
    <div style={styles.boxContainer}>
      <div className="box-art" id="boxOne" />
      <div className="box-art" id="boxTwo" />
      <div className="box-art" id="boxThree" />
    </div>
  );
};

const Header = ({ isMobile }) => {

  return (
    <header>
      <div style={{display: 'flex'}}> 
        {headerArt()}
          <div style={{ marginLeft: '10px' }}>
            <h1>Jenifer Donahue Esq.</h1>
            <h2>Family Law</h2>
          </div>
      </div>
        <NavBar/> 
    </header>
  );
};

const styles = {
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginRight: '10px',
  },
};

export default Header;
