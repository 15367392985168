import React from 'react';

export default function Home({ isMobile }) {
  return (
    <>
      <div className="body-header">Home</div>
      <div className="content-container home-container">
        <div style={styles.homeGrid}>
          <div style={{ textAlign: 'center' }}>
            <img
              alt="Homepage"
              src="images/homepage.webp"
              width={isMobile ? '85%' : '80%'}
              height="auto"
              style={{ marginBottom: '20px' }}
            />
            <p>{text.firstPara}</p>
          </div>
          <div style={styles.rightColumn}>
            <p>{text.secondPara}</p>
            <p>{text.thirdPara}</p>
          </div>
        </div>
      </div>
    </>
  );
}

const text = {
  firstPara:
    'We specialize in the practice of family law and take a personalized and concerned approach to your domestic law issues. With more than 15 years of experience serving clients in Chester, Delaware and Montgomery counties, we aim to make your divorce, as well as the resolution of any other family law issue, as efficient and cost effective as possible.',
  secondPara:
    'We make the best out of a difficult situation. Although there are arguably no good divorces, there are definitely bad divorces. There are also extremely long and costly divorces. We aim to make your divorce, as well as the resolution of any other family law issue, as efficient and cost effective as possible. Our hourly rates are competitive with other Main Line firms. We advocate negotiating every aspect of divorce as opposed to litigating any aspect of divorce so long as the parties are willing to cooperate in negotiations and there is fair and full disclosure by both parties of all relevant information.',
  thirdPara:
    'We prefer to negotiate the terms of support, custody and property division in a way that causes the least amount of distress to all family members yet provides a fair and equitable result serving the needs of the entire family.',
};

const styles = {
  homeGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridColumnGap: '80px',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};
