export default function Areas({ isMobile }) {
  const areaOfPracticeCard = cardData => {
    const dynamicCardContainer = {
      ...styles.cardContainer,
      marginBottom: isMobile ? '16px' : '0px',
    };

    const dynamicCardTitle = {
      ...styles.cardTitle,
      fontSize: isMobile ? '1em' : '1.2em',
    };

    const dynamicCardText = {
      ...styles.cardText,
      fontSize: isMobile ? '0.9em' : '1.1em',
    };

    return (
      <div key={cardData.title} style={dynamicCardContainer}>
        {!isMobile && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              alt={cardData.title}
              src={cardData.imageUrl}
              objectfit="cover"
            />
          </div>
        )}
        <div style={styles.cardTextContainer}>
          <div style={dynamicCardTitle}>{cardData.title}</div>
          <div style={dynamicCardText}>{cardData.text}</div>
        </div>
      </div>
    );
  };

  const dynamicAreasContainer = isMobile
    ? styles.mobileAreasGrid
    : styles.areasGrid;

  return (
    <>
      <div className="body-header">Areas Of Practice</div>
      <div className="content-container areas-content-container">
        <div style={dynamicAreasContainer}>
          {focusAreas.map(cardData => areaOfPracticeCard(cardData))}
        </div>
      </div>
    </>
  );
}

const styles = {
  areasGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '20px',
    gridRowGap: '10px',
  },
  mobileAreasGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
  },
  cardTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  cardTitle: {
    fontFamily: 'News Cycle',
    fontWeight: 'bold',
    marginBottom: 4,
  },
  cardText: {
    fontFamily: 'News Cycle',
    fontSize: '1.1em',
    lineHeight: 1.2,
  },
};

const focusAreas = [
  {
    imageUrl: 'images/divorce.webp',
    title: 'Divorce',
    text: 'Whether the terms of your divorce are negotiated and memorialized in a Property Settlement Agreement or determined by a Master or Judge as a result of litigation, we are prepared to protect your interests. In addition, should you desire to participate in collaborative divorce, where there is an express agreement not to litigate, or to go to mediation, we will be sure that you are zealously represented.',
  },
  {
    imageUrl: 'images/alimony.webp',
    title: 'Alimony/Spousal Support',
    text: 'During the pendency of the divorce action, the dependent spouse is entitled to alimony pendente lite or spousal support to ensure an adequate income to that spouse. At divorce, alimony may be awarded, or an unequal distribution of marital property may be made to the dependent spouse in lieu of alimony, after weighing the various factors affecting property division under the Divorce Code.',
  },
  {
    imageUrl: 'images/property.webp',
    title: 'Property Division',
    text: 'Property division can be very simple or very difficult or somewhere in between. We are experienced in assessing whether property is premarital, nonmarital or marital and negotiating the division of real estate, personal property, business interests, stock and investment accounts, employee benefits, including stock options and pensions, as well as other types of retirement plans.',
  },
  {
    imageUrl: 'images/child_custody.webp',
    title: 'Child Custody',
    text: 'We will help you assess the myriad of issues to be considered in child custody, such as geographic distance between parents, quality of school districts, preferences of the child, age of the child, and more, in order to determine an arrangement which is in the child’s and the family’s best interest.',
  },
  {
    imageUrl: 'images/child_support.webp',
    title: 'Child Support',
    text: 'Although support is primarily determined by the Pennsylvania Support Guidelines, there are a host of other issues which can impact support, including medical and dental needs, childcare, college costs, extracurricular activities and camp. We can help you address these concerns.',
  },
  {
    imageUrl: 'images/premarital.webp',
    title: 'Premarital Agreements',
    text: 'For first marriages, it is advisable to have a prenuptial in place if there are considerable premarital assets, regular family gifts, large expected inheritance, or interest by a party in a family owned business. For second marriages, especially when there are children of the first marriage(s) and/or significant assets at the time of the marriage, a prenuptial agreement is frequently advisable.',
  },
  {
    imageUrl: 'images/collaborative.webp',
    title: 'Collaborative Law',
    text: 'We are associates in the Collaborative Family Law Affiliates (CFLA), a professional association of attorneys, financial planners and mental health professionals. See www.collaborativefamilylaw.com. Our goal is to represent you in custody, support, and division of assets without going to court. Both spouses must be represented by an attorney if this process is to be employed.  ',
  },
  {
    imageUrl: 'images/agreements.webp',
    title: 'Enforcing Agreements',
    text: 'We are prepared to get enforcement for you of your Property Settlement Agreement, should there be a breach of contract by the other party. This is usually done through litigation although there would always be an attempt at negotiation.  ',
  },
];
