export default function Biography() {
  return (
    <>
      <div className="body-header">Biography of Jenifer Donahue, Esq.</div>
      <div className="content-container bio-content-container">
        <p>{text.firstPara}</p>
        <p>{text.secondPara}</p>

        <p>{text.thirdPara}</p>
        <p>{text.fourthPara}</p>
        <ul>
          <li>{text.fourthSubOne}</li>
          <li>{text.fourthSubTwo}</li>
          <li>{text.fourthSubThree}</li>
        </ul>
      </div>
    </>
  );
}

const text = {
  firstPara:
    'Jenifer Donahue graduated from University of Virginia with a B.A. in Psychology. She graduated from University of Pittsburgh School of Law, after serving for two years on the Law Review, the last of which she was Notes and Comments Editor.',
  secondPara:
    'Following law school, Jenifer Donahue had a two year clerkship with the late Honorable James R. Cavanaugh, a Judge on the Pennsylvania Superior Court.',
  thirdPara:
    'Later, Jenifer Donahue worked as an associate for Stradley, Ronon Steven and Young in Philadelphia. She was a Legal Consultant at Delaware Express Shuttle, Inc. and temporary in-house counsel for a startup company. Before starting her firm, she was an associate at a boutique Main Line family law firm.',
  fourthPara: 'Jenifer is an active member of the following:',
  fourthSubOne: 'PA Bar Association',
  fourthSubTwo: 'Certified Mediator',
  fourthSubThree: 'Collaborative Law Affiliates',
};
